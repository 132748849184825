.GAdsHeroBanner-container
{
    background-image: url("../../../public/images/creatives/gads-hero-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    min-height: 650px;
    overflow-y: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}
.GAdsHeroBanner-container::-webkit-scrollbar {
  display: none; 
}

.GAdsHeroBanner-container .main-heading,
.GAdsHeroBanner-container .main-heading span
{
    letter-spacing: 0px;
    font-size: 180px;
}

.GAdsHeroBanner-container .overlay
{
    background-color: #f0f2f5be;
}

.GAdsHeroBanner-container .about-hero-img
{
    height: 30rem;
}

.GAdsHeroBanner-container .main-heading
{
    position: relative;
}

.GAdsHeroBanner-container .main-heading .txt-1{
    position: relative;
    /* top: -80px; */
    left: 14%;
    z-index: 1;
    
    animation-name: GAdsHeroBanner-container-txt1;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    
}
.GAdsHeroBanner-container .main-heading .about-hero-img{
    position: relative;
    z-index: 2;
    animation-name: GAdsHeroBanner-fade;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.GAdsHeroBanner-container .main-heading .txt-2{
    position: relative;
    left: -5%;
    z-index: 3;
    
    animation-name: GAdsHeroBanner-container-txt2;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@media(max-width:1340px)
{
    .GAdsHeroBanner-container .main-heading,
    .GAdsHeroBanner-container .main-heading span
    {
        font-size: 120px;
    }
}

@media(max-width:1000px)
{
    .GAdsHeroBanner-container .main-heading,
    .GAdsHeroBanner-container .main-heading span
    {
        font-size: 90px;
    }
    .GAdsHeroBanner-container .main-heading .txt-1{
        top: 0;
        left: 0;
        animation: none;
    
    }
    
    .GAdsHeroBanner-container .main-heading .txt-2{
        left: 0;
        animation: none;
    }
    .GAdsHeroBanner-container
    {
        min-height: 100vh;
    }
    .GAdsHeroBanner-container .about-hero-img
    {
        height: 20rem;
        animation: none;
    }
}

@media(min-width:1774px)
{
    .GAdsHeroBanner-container .main-heading,
    .GAdsHeroBanner-container .main-heading span
    {
        letter-spacing: 20px;
        font-size: 200px;

    }
    .GAdsHeroBanner-container .main-heading .txt-1{
        /* top: -80px; */
        left: 8%;
        z-index: 1;
        animation-name: GAdsHeroBanner-container-txt1-lg;
    }
    .GAdsHeroBanner-container .main-heading .about-hero-img{
      position: relative;
        z-index: 2;
        left: -6%;
    }
    
    .GAdsHeroBanner-container .main-heading .txt-2{
        position: relative;
        left: -14%;
        font-size: 280px;
        z-index: 3;
        animation-name: GAdsHeroBanner-container-txt2-lg;
    }
}

@keyframes GAdsHeroBanner-container-txt1 {
    from {
      left: -100vw;
    }
    to {
      left: 14%;
    }
  }
  
  @keyframes GAdsHeroBanner-container-txt1-lg {
    from {
      left: -100vw;
    }
    to {
      left: 8%;
    }
  }
  @keyframes GAdsHeroBanner-container-txt2 {
    from {
      left: 100vw;
    }
    to {
      left: -5%;
    }
  }
  
  @keyframes GAdsHeroBanner-container-txt2-lg {
    from {
      left: 100vw;
    }
    to {
      left: -14%;
    }
  }
  @keyframes GAdsHeroBanner-fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }