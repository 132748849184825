*{
  
  scroll-behavior: smooth; 
}

.font-bebus
{
  font-family: "Bebas Neue", sans-serif;
}
.font-manrope
{
  font-family: "Manrope", sans-serif;
}

.color-blue
{
  color: #023c58!important;
}
.color-yellow
{
  color: #f58e09;
}
.color-white
{
  color: white!important;
}

.font-bold
{
  font-weight: bold;
  letter-spacing: 2.5px;
}

.genral-btn
{
  border-radius: 24px;
  padding:12px 45px;
  font-family: "Manrope", sans-serif;
  font-size: 13px;
  
}

.btn-blue
{
  background-color: #023c58;
  border: 1px solid #023c58;
  color: white;
  transition: .5s;
}
.btn-blue:hover
{
  background-color: #011119;
  border: 1px solid #023c58;
}
.btn-yellow
{
  background-color: #f58e09;
  border: 1px solid #f58e09;
  color:  #0a0a0a;
  transition: .5s;
}
.btn-yellow:hover
{
  background-color: #df8107;
  color:rgba(255, 255, 255, 0.699);
}
.btn-trans
{
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid  #313131;
  color:  #313131;
  transition: .5s;
}
.btn-trans:hover
{
  background-color:  #313131;
  color: white;
}

.hero-heading-font-size
{
  font-size: 72px;
}

.body-heading
{
  font-size: 50px;
  letter-spacing: 1.5px;
  font-family: "Bebas Neue", sans-serif;
}
.body-paragraph
  {
    font-size: 18px;
    font-family: "Manrope", sans-serif;
    color: #313131;
  }


@media(min-width:1400px)
{
  .body-heading
  {
    font-size: 83px;
    letter-spacing: 1.5px;
    font-family: "Bebas Neue", sans-serif;;
  }
  .genral-btn
  {
    padding:18px 65px;
    border-radius: 34px;
    font-weight:  600 ;
    letter-spacing: 2.24px;
  }
  .body-paragraph
  {
    font-size: 18px;
    font-family: "Manrope", sans-serif;
    color: #313131;
    line-height: 39px;
  }
}

@media(max-width:992px)
{
  .body-heading
  {
    font-size: 40px;
  }
}