.UIHeroBanner-container
{
    background-image: url("../../../public/images/creatives/ui-hero-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    min-height: 650px;
    overflow-y: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}
.UIHeroBanner-container::-webkit-scrollbar {
  display: none; 
}

.UIHeroBanner-container .main-heading,
.UIHeroBanner-container .main-heading span
{
    letter-spacing: 0px;
    font-size: 200px;
}

.UIHeroBanner-container .overlay
{
    background-color: #f0f2f5be;
}

.UIHeroBanner-container .about-hero-img
{
    height: 30rem;
}

.UIHeroBanner-container .main-heading
{
    position: relative;
}

.UIHeroBanner-container .main-heading .txt-1{
    position: relative;
    /* top: -80px; */
    left: 24%;
    z-index: 1;
    animation: movetoptobottomPosition 1s ease-in-out forwards;
}
.UIHeroBanner-container .main-heading .about-hero-img{
  position: relative;
    z-index: 2;
    animation: fade 1s ease-in-out forwards;
}

.UIHeroBanner-container .main-heading .txt-2{
    position: relative;
    left: -18%;
    z-index: 3;
    animation: movebottomtotopPosition 1s ease-in-out forwards;
}

@media(max-width:1000px)
{
    .UIHeroBanner-container .main-heading,
    .UIHeroBanner-container .main-heading span
    {
        font-size: 90px;
    }
    .UIHeroBanner-container .main-heading .txt-1{
        top: 0;
        left: 0;
        animation: none;
    
    }
    
    .UIHeroBanner-container .main-heading .txt-2{
        left: 0;
        animation: none;
    }
    .UIHeroBanner-container
    {
        min-height: 100vh;
    }
    .UIHeroBanner-container .about-hero-img
    {
        height: 20rem;
        animation: none;
    }
}

@media(min-width:1700px)
{
    .UIHeroBanner-container .main-heading,
    .UIHeroBanner-container .main-heading span
    {
        letter-spacing: 20px;
        font-size: 350px;

    }
    .UIHeroBanner-container .main-heading .txt-1{
        top: -80px;
        left: 24%;
        z-index: 1;
    
    }
    .UIHeroBanner-container .main-heading .about-hero-img{
      position: relative;
        z-index: 2;
    }
    
    .UIHeroBanner-container .main-heading .txt-2{
        position: relative;
        left: -18%;
        z-index: 3;
    }
}

@keyframes movetoptobottomPosition {
    from {
        opacity: 0.25;
        top: -100vh;
    }
    to {
        opacity: 1;
        top: -80px;
    }
  }
@keyframes movebottomtotopPosition {
    from {
        opacity: 0.25;
        bottom: -100vh;
    }
    to {
        opacity: 1;
        bottom: 0px;
    }
  }
@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }