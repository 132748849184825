.common_hero_section {
  color: black;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
 
  width: 100%;
  height: 40rem;
  min-height: 100vh;

}

/* .common_hero_section .bg-effect
{
  background: rgba(167, 166, 166, 0.367);
  background: linear-gradient(0deg, rgba(91, 91, 91, 0.445) 0%, rgba(76, 75, 75, 0.377) 100%);
} */
.common_hero_section .bg-effect
{
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient( rgba(255, 255, 255, 0), #0a1a3b0d,#0818392f,#09193A);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index:-1 ;
}

.common_hero_section .hero-paragraph
{
  width: 60%;
  margin: auto;
}

.common_hero_section .hero-container
{
  border-radius: 20px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.common_hero_section .wrapper
{
  
  border-radius: 20px;
  height: 100%;
}


@media(max-width :760px)
{
  .h-cus-auto
  {
    height: auto!important;
  }
  
  .common_hero_section .hero-paragraph
  {
    width: 100%;
    margin: auto;
  }
}

@media(max-width : 660px)
{
  .common_hero_section .hero-text-content .body-paragraph 
  {
    width : 100%;
  }
 
}

@media(max-width : 400px)
{
  .common_hero_section .hero_content_wrapper .hero-btn
  {
    padding: 0.6rem 1.8rem;
    font-size: 15px;
  }
  .common_hero_section .icon-container img{
    width: 25px;
    height: 25px;
  }
  .common_hero_section .verticle-text
{
    font-size: 18px;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    font-family: "Playfair Display"!important;
}
}