h1,h2,h3,h4,h5,h6,p,a,img,button{
    user-select: none;
}
.line-seprator-casest
{
    width: 100%;
    height: 0;
    border-bottom: 1px solid white;
}

@media(min-width:1300px)
{
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
        max-width: 85%!important;
    }
}