.Footer-container
{
    background-color: #040404;
}

.Footer-container .line-seprator
{
    width: 100%;
    height: 0;
    border: 1px solid grey;
    margin-top: 60px;
}

.Footer-container .body-paragraph{
    color: #F0F2F5;
}