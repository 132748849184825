.AboutHeroBanner-container
{
    background-image: url("../../../public/images/creatives/about-hero-bg.jpg");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    min-height: 650px;
    max-height: 800px;
    overflow-y: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}
.AboutHeroBanner-container::-webkit-scrollbar {
  display: none; 
}

.AboutHeroBanner-container .main-heading,
.AboutHeroBanner-container .main-heading span
{
    letter-spacing: 6px;
    font-size: 100px;
}

.AboutHeroBanner-container .overlay
{
    background-color: #f0f2f5be;
}

.AboutHeroBanner-container .about-hero-img
{
    height: 35rem;
    transform: translate(100px,-85px);
}

.AboutHeroBanner-container .main-heading
{
    position: relative;
}

.AboutHeroBanner-container .main-heading .txt-1{
    position: relative;
    /* top: -80px;
    left: 12%; */
    z-index: 1;
    transition: 1.5s;
    animation: movelefttorightToPosition 1s ease-in-out forwards;
}
.AboutHeroBanner-container .main-heading .about-hero-img{
  position: relative;
    z-index: 2;
    animation: movetoptobottomToPosition 1s ease-in-out forwards;
}

.AboutHeroBanner-container .main-heading .txt-2{
    position: relative;
    /* left: -10; */
    z-index: 3;
    animation: moverighttoleftToPosition 1s ease-in-out forwards;
}
@media(max-width:1440px)
{
   
    .AboutHeroBanner-container .about-hero-img
    {
        height: 32rem;
    }
}
@media(max-width:1374px)
{
   
    .AboutHeroBanner-container .about-hero-img
    {
        height: 30rem;
    }
}
@media(max-width:1327px)
{
    .AboutHeroBanner-container .main-heading,
    .AboutHeroBanner-container .main-heading span
    {
        font-size: 95px;
    }
}
@media(max-width:1200px)
{
    .AboutHeroBanner-container .main-heading,
    .AboutHeroBanner-container .main-heading span
    {
        font-size: 65px;
    }
    .AboutHeroBanner-container .about-hero-img
    {
        height: 28rem;
        transform: translate(100px,-85px);
    }
}


@media(max-width:991px)
{
    .AboutHeroBanner-container .main-heading,
    .AboutHeroBanner-container .main-heading span
    {
        font-size: 45px;
    }
    .AboutHeroBanner-container .main-heading .txt-1{
        top: 0;
        left: 0;
        animation:none;
    }
    
    .AboutHeroBanner-container .main-heading .txt-2{
        left: 0;
        animation:none;
    }
    .AboutHeroBanner-container
    {
        
        min-height: 100vh;
    }
}

@media(min-width:1900px)
{
    .AboutHeroBanner-container .main-heading,
    .AboutHeroBanner-container .main-heading span
    {
        letter-spacing: 6px;
        font-size: 140px;
    }
    .AboutHeroBanner-container .main-heading .txt-1{
        top: -80px;
        left: 12%;
        z-index: 1;
        animation: movelefttorightToPositionlg 1s ease-in-out forwards;
    
    }
    .AboutHeroBanner-container .main-heading .about-hero-img{
      position: relative;
        z-index: 2;
        height: 60rem;
        transform: translate(100px,-85px);
    }
    
    .AboutHeroBanner-container .main-heading .txt-2{
        position: relative;
        left: -9px;
        z-index: 3;
        animation: moverighttoleftToPositionlg 1s ease-in-out forwards;
    }
    @keyframes moverighttoleftToPositionlg {
        from {
            left: 200px;
        }
        to {
            left: -29%;
            z-index: 3;
        }
      }
      @keyframes movelefttorightToPositionlg {
        from {
          top: -80px;
          left: -200px;
        }
        to {
          top: -80px;
          left: 25%;
        }
      }
}
@media(min-width:1800px)
{
    .AboutHeroBanner-container .main-heading,
    .AboutHeroBanner-container .main-heading span
    {
        letter-spacing: 6px;
        font-size: 120px;
    }
    .AboutHeroBanner-container .main-heading .about-hero-img{
      position: relative;
        z-index: 2;
        height: 50rem;
        /* transform: translate(100px,-85px); */
    }
}
@media(min-width:1606px)
{
    .AboutHeroBanner-container .main-heading,
    .AboutHeroBanner-container .main-heading span
    {
        letter-spacing: 6px;
        font-size: 110px;
    }
    .AboutHeroBanner-container .main-heading .about-hero-img{
      position: relative;
        z-index: 2;
        height: 40rem;
        transform: translate(100px,-85px);
    }
}

@keyframes movelefttorightToPosition {
    from {
      top: -80px;
      left: -200px;
    }
    to {
      top: -80px;
      left: 27%;
    }
  }
@keyframes movetoptobottomToPosition {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
@keyframes moverighttoleftToPosition {
    from {
        left: 200px;
    }
    to {
        left: -22%;
        z-index: 3;
    }
  }