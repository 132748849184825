.SocialMediaHeroBanner-container
{
    background-image: url("../../../public/images/creatives/social-media-hero-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    min-height: 650px;
    overflow-y: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}
.SocialMediaHeroBanner-container::-webkit-scrollbar {
  display: none; 
}

.SocialMediaHeroBanner-container .main-heading,
.SocialMediaHeroBanner-container .main-heading span
{
    letter-spacing: 6px;
    font-size: 180px;
}

.SocialMediaHeroBanner-container .overlay
{
    background-color: #f0f2f5be;
}

.SocialMediaHeroBanner-container .about-hero-img
{
    height: 30rem;
}

.SocialMediaHeroBanner-container .main-heading
{
    position: relative;
}

.SocialMediaHeroBanner-container .main-heading .txt-1{
    position: relative;
    top: 160px;
    /* left: 14%; */
    z-index: 1;
    animation-name: SocialMediaHeroBanner-container-txt1;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

}
.SocialMediaHeroBanner-container .main-heading .about-hero-img{
  position: relative;
  height: 20rem;
    z-index: 2;
    animation-name: SocialMediaHeroBanner-container-fade;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.SocialMediaHeroBanner-container .main-heading .txt-2{
    position: relative;
    top: -140px;
    z-index: 3;
    animation-name: SocialMediaHeroBanner-container-txt2;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@media(max-width:1340px)
{
    /* .SocialMediaHeroBanner-container .main-heading,
    .SocialMediaHeroBanner-container .main-heading span
    {
        font-size: 120px;
    } */
}

@media(max-width:992px)
{
    .SocialMediaHeroBanner-container .main-heading,
    .SocialMediaHeroBanner-container .main-heading span
    {
        font-size: 90px;
    }
    .SocialMediaHeroBanner-container .main-heading .txt-1{
        top: 0;
        left: 0;
        animation: none;
    
    }
    
    .SocialMediaHeroBanner-container .main-heading .txt-2{
        top: 0;
        left: 0;
        animation: none;
    }
    .SocialMediaHeroBanner-container
    {
        min-height: 100vh;
    }
    .SocialMediaHeroBanner-container .about-hero-img
    {
        height: 20rem;
        animation: none;
    }
}

@media(min-width:1774px)
{
    .SocialMediaHeroBanner-container .main-heading,
    .SocialMediaHeroBanner-container .main-heading span
    {
        letter-spacing: 20px;
        font-size: 200px;

    }
    .SocialMediaHeroBanner-container .main-heading .txt-1{
        top: 160px;
        left:0%;
        z-index: 1;
    
    }
    .SocialMediaHeroBanner-container .main-heading .about-hero-img{
      position: relative;
        z-index: 2;
    }
    
    .SocialMediaHeroBanner-container .main-heading .txt-2{
        position: relative;
        top: -140px;
        z-index: 3;
    }
}

@keyframes SocialMediaHeroBanner-container-txt1 {
    from {
      top: -100vh;
    }
    to {
      top: 160px;
    }
  }
  
@keyframes SocialMediaHeroBanner-container-txt2 {
    from {
      top: 100vw;
    }
    to {
      top: -140px;
    }
  }
  
  

  @keyframes SocialMediaHeroBanner-container-fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }