/* Base styles */
.SeoHeroBanner-container {
    background-image: url("../../../public/images/creatives/seo-hero-bg.jpg");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    min-height: 650px;
    overflow-y: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}
.SeoHeroBanner-container::-webkit-scrollbar {
  display: none; 
}
  
  .SeoHeroBanner-container .main-heading,
  .SeoHeroBanner-container .main-heading span {
    letter-spacing: 0px;
    font-size: 90px;
  }
  
  .SeoHeroBanner-container .overlay {
    background-color: #f0f2f5be;
  }
  
  .SeoHeroBanner-container .about-hero-img {
    height: 30rem;
    animation-name: rotate;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  
  .SeoHeroBanner-container .main-heading {
    position: relative;
  }
  
  .SeoHeroBanner-container .main-heading .txt-1 {
    position: relative;
    top: -80px;
    z-index: 1;
    animation-name: txt1;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  
  .SeoHeroBanner-container .main-heading .about-hero-img {
    position: relative;
    display: none;
    z-index: 2;
  }
  
  .SeoHeroBanner-container .main-heading .txt-2 {
    position: relative;
    left: -18%;
    z-index: 3;
    animation-name: txt2;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  
  /* Media query for screens smaller than 1200px */
  @media (max-width: 1200px) {
    .SeoHeroBanner-container .main-heading,
    .SeoHeroBanner-container .main-heading span {
      font-size: 72px;
    }
    .SeoHeroBanner-container .main-heading .txt-1 {
      top: 0;
      left: 0;
      animation: none;
    }
    .SeoHeroBanner-container .main-heading .txt-2 {
      left: 0;
      animation: none;
    }
    .SeoHeroBanner-container {
      min-height: 100vh;
    }
    .SeoHeroBanner-container .about-hero-img {
      height: 20rem;
    }
  }
  
  /* Media query for screens larger than 1200px but smaller than 1600px */
  @media (min-width: 1200px) and (max-width: 1599px) {
    .SeoHeroBanner-container .main-heading {
      position: static;
    }
    .SeoHeroBanner-container .main-heading,
    .SeoHeroBanner-container .main-heading span {
      letter-spacing: 20px;
      font-size: 120px;
    }
    .SeoHeroBanner-container .main-heading .txt-1 {
      top: 0px;
      left: 0%;
      z-index: 1;
      animation-name: txt1;
    }
    .SeoHeroBanner-container .main-heading .about-hero-img {
      position: absolute;
      display: block;
      height: 50rem;
      left: 45%;
      top: -10%;
      z-index: 2;
    }
    .SeoHeroBanner-container .main-heading .txt-2 {
      position: relative;
      left: 0%;
      z-index: 3;
      animation-name: txt2;
    }
  }
  
  /* Media query for screens larger than 1600px */
  @media (min-width: 1600px) {
    .SeoHeroBanner-container .main-heading {
      position: static;
    }
    .SeoHeroBanner-container .main-heading,
    .SeoHeroBanner-container .main-heading span {
      letter-spacing: 20px;
      font-size: 120px;
    }
    .SeoHeroBanner-container .main-heading .txt-1 {
      top: 0px;
      left: 0%;
      z-index: 1;
      animation-name: txt1-lg;
    }
    .SeoHeroBanner-container .main-heading .about-hero-img {
      position: absolute;
      display: block;
      height: 60rem;
      left: 45%;
      top: -10%;
      z-index: 2;
      animation-name: fade;
    }
    .SeoHeroBanner-container .main-heading .txt-2 {
      position: relative;
      left: 0%;
      z-index: 3;
      animation-name: txt2-lg;
    }
  }
  
  /* Keyframes */
  @keyframes txt1 {
    from {
      left: -100vw;
    }
    to {
      left: -10%;
    }
  }
  
  @keyframes txt1-lg {
    from {
      left: -100vw;
    }
    to {
      left: -20%;
    }
  }
  @keyframes txt2 {
    from {
      left: 100vw;
    }
    to {
      left: 18%;
    }
  }
  
  @keyframes txt2-lg {
    from {
      left: 100vw;
    }
    to {
      left: 30%;
    }
  }
  
  @keyframes rotate {
    from {
      transform: rotate(100deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  