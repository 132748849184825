.popular-blog-container
{

}

.popular-blog-container .body-heading-b
{
    font-size: 2rem;
}

.popular-blog-container .blog-card
{
    border-radius: 20px;
    padding: 8px 14px;
    cursor: pointer;
    transition: .3s;
}

.popular-blog-container .blog-card .title
{
    font-size: 21px;
    font-weight: 700;
}

.popular-blog-container .blog-card:hover
{
    background-color: #6161612b;
}
.popular-blog-container .blog-card .blog_list_logo
{
    gap: 10px;
}
.popular-blog-container .blog-card .blog_list_logo_img
{
    width: 60px;
    height: 60px;
}

.popular-blog-container .blog-card .blog_list_logo_title
{
    font-size: 1.4rem;
    color: #ffffff;
    font-weight: 600;
}

.popular-blog-container .blog-card .blog_list_logo_date
{
    font-size: .9rem;
    color:#fcfcfc;
}
.popular-blog-container .blog-card .blog_list_logo_date span
{
    margin-left: 5px;
    font-weight: 600;
}

.popular-blog-container .blog-card .card-anchor
{
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
}

.popular-blog-container .blog-card .color-blue
{
    color:#2245D1;
}

.popular-blog-container .blog-card .card-img
{
    border-radius: 20px;
    /* -webkit-box-shadow: 0px 0px 15px 15px rgba(133, 133, 133, 0.173);
    -moz-box-shadow: 0px 0px 15px 15px rgba(133, 133, 133, 0.173);
    box-shadow: 0px 0px 15px 15px rgba(133, 133, 133, 0.173); */
}

.popular-blog-container .popular-card-container
{
    border-radius: 24px;
    background: #202e4c;
}

.popular-blog-container .popular-card .card-title
{
    color: #38B3E4!important;
    font-size: 1rem;
    font-weight: 600;
}
.popular-blog-container .popular-card .card-date
{
    color:#D8D8D8;
}

.popular-blog-container .blog-card .blog_list_logo_date .card-date
{
    font-size: .9rem;
    color:#D8D8D8;
}


.popular-blog-container .popular-card .popular-blog-img
{
    width: 50%;
}

.popular-blog-container .page-btn-container button
{
    padding:7px 14px;
    border: 1px solid #B9B9B9;
    background-color: transparent;
    border-radius: 7px;
    color: #ffffff;
    box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.1);
}

.popular-blog-container .page-btn-container button .next-icon
{
    width: 10px;
    height: 10px;
    margin: 0;
}
.popular-blog-container .page-btn-container .active
{
    background-color: #39B4E5;
}

@media(min-width:1400px)
{
    .popular-blog-container .blog-card .title
    {
        font-size: 25px;
        font-weight: 700;
    }
}