.letwork-container
{
    background-image: url("../../../public/images/creatives/home-hero-bg.png");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
}

.letwork-container .hero-heading-font-size
{
    letter-spacing: 5px;
}

@media(min-width:1400px)
{
    .letwork-container .heading-large
    {
        font-size: 142px;
    }
}