.NavLinks-container
{
}
.NavLinks-container ul li
{
    list-style: none;
    cursor: pointer;
    border-bottom: 1px solid rgb(126, 126, 126);
}
.NavLinks-container ul li:last-child
{
    list-style: none;
    cursor: pointer;
    border-bottom: none;
}
.NavLinks-container .slide ul li
{
    border-bottom: none;
}
.NavLinks-container .slide ul li a
{
    color: #F0F2F5;
    font-size: 25px;
}
.NavLinks-container ul li a
{
    
    text-decoration: none;
}

.NavLinks-container .nav-links
{
    color: #F58E09;
    font-size: 34px;
    font-weight: bold;
    transition: .3s;
}
.NavLinks-container .nav-links:hover
{
    color: #d57900;

}


.NavLinks-container .slide {
    position: relative;
    width: 100%;
    overflow-y: hidden;
  }
  .NavLinks-container .slide-in-cus {
    animation: slide-in-cus 0.5s forwards;
    -webkit-animation: slide-in-cus 0.5s forwards;
  }
  .NavLinks-container .slide-out-cus {
    animation: slide-out-cus 0.5s forwards;
    -webkit-animation: slide-out-cus 0.5s forwards;
  }

  @keyframes slide-in-cus {
    0% { max-height: 0px; }
    100% { max-height: 600px; }
  }
  
  @-webkit-keyframes slide-in-cus {
    0% { max-height: 0px; }
    100% { max-height: 600px; }
  }
    
  @keyframes slide-out-cus {
    0% { max-height: 600px; }
    100% { max-height:0px; }
  }
  
  @-webkit-keyframes slide-out-cus {
    0% { max-height: 600px; }
    100% { max-height: 0px; }
  }

  @media(min-width:1400px)
  {
    .NavLinks-container .nav-links
    {
        color: #F58E09;
        font-size: 45px;
        font-weight: bold;
        transition: .3s;
    }
  .NavLinks-container .slide ul li a
  {
      color: #F0F2F5;
      font-size: 30px;
  }
  }