.TextContent-container
{
    background-color: #000000;
    overflow-x: hidden;
}


/* .TextContent-container .ani-lr.show-animation
{
    animation: TextContent-container-lr .8s ease-in-out forwards;
}
.TextContent-container .ani-rl.show-animation
{
    animation: TextContent-container-rl .8s ease-in-out forwards;
} */

@media(min-width:1400px)
{
    .TextContent-container .body-paragraph
    {
        line-height: 2;
        width: 70%;
    }

}

@keyframes TextContent-container-lr {
    from {
        position: relative;
        left: -100vw;
        opacity: 0;
    }
    to {
        position: relative;
        left: 0;
        opacity: 1;
    }
  }
@keyframes TextContent-container-rl {
    from {
        position: relative;
        left: 100vw;
        opacity: 0;
    }
    to {
        position: relative;
        left: 0;
        opacity: 1;
    }
  }