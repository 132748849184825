.HomeHeroBanner-container
{
    background-image: url("../../../public/images/creatives/home-hero-bg.png");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
    
    height: 100vh;
    min-height:1000px;
    overflow-y: hidden;  
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}
.HomeHeroBanner-container::-webkit-scrollbar {
  display: none; 
}

.HomeHeroBanner-container .main-heading,
.HomeHeroBanner-container .main-heading span
{
    letter-spacing: 6px;
}

.HomeHeroBanner-container .overlay
{
    background-color: #f0f2f5be;
}

.HomeHeroBanner-container .ani-lr
{
    animation: HomeHeroBanner-container-lr 1s ease-in-out forwards;
}
.HomeHeroBanner-container .ani-rl
{
    animation: HomeHeroBanner-container-rl 1s ease-in-out forwards;
}
.HomeHeroBanner-container .ani-tb
{
    animation: HomeHeroBanner-container-tb 1s ease-in-out forwards;
}
.HomeHeroBanner-container .ani-bt
{
    animation: HomeHeroBanner-container-bt 1s ease-in-out forwards;
}
.HomeHeroBanner-container .ani-fade
{
    animation: HomeHeroBanner-container-fade 1.5s ease-in-out forwards;
}

@media(min-width:1400px)
{
    .HomeHeroBanner-container
    {
        
        min-height:1150px;
    }
    .HomeHeroBanner-container .main-heading,
    .HomeHeroBanner-container .main-heading span
    {
        letter-spacing: 15px;
        font-size: 125px;
        font-weight: bold;
    }
    .HomeHeroBanner-container .img-1
    {
        height: 120px!important;
        width: 120px!important;
    }
    .HomeHeroBanner-container .img-2
    {
        height: 120px!important;
        width: 120px!important;

    }
    .HomeHeroBanner-container .img-3
    {
        height: 150px!important;
        width: 80px!important;

    }
}

@media(max-width:1000px)
{
    
    .HomeHeroBanner-container
    {
        height: auto;
        min-height:700px;
    }
    .HomeHeroBanner-container .main-heading,
    .HomeHeroBanner-container .main-heading span
    {
        letter-spacing: 3px;
        font-size: 45px;
    }
}

@keyframes HomeHeroBanner-container-lr {
    from {
        position: relative;
        left: -100vw;
    }
    to {
        position: relative;
        left: 0;
    }
  }
@keyframes HomeHeroBanner-container-rl {
    from {
        position: relative;
        left: 100vw;
    }
    to {
        position: relative;
        left: 0;
    }
  }
@keyframes HomeHeroBanner-container-tb{
    from {
        position: relative;
        top: -100vh;
    }
    to {
        position: relative;
        top: 0;
    }
  }
@keyframes HomeHeroBanner-container-bt{
    from {
        position: relative;
        top: 100vw;
    }
    to {
        position: relative;
        top: 0;
    }
  }
@keyframes HomeHeroBanner-container-fade{
    from {
     opacity: 0;
    }
    to {
      opacity: 1;
    }
  }