.MyServices-container
{

}

.MyServices-container .service-card-container
{
    border: 2px dashed #707070 ;
    border-radius: 14px;
}

.MyServices-container .service-card-container .card-title
{
    font-size: 31px;
    font-weight: bold;
    /* letter-spacing: 0px; */
}
.MyServices-container .service-card-container .card-content
{
    font-size: 16px;
}
.MyServices-container .service-card-container .card-anchor
{
    font-size: 16px;
    cursor: pointer;
    transition: .3s;
}
.MyServices-container .service-card-container .card-anchor:hover
{
    color: #023c58;
}

.MyServices-container .card-sized-box
{
    overflow-y: scroll;
    height: 420px;
}


.MyServices-container .card-sized-box {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none; 
}

.MyServices-container .card-sized-box::-webkit-scrollbar {
    display: none; 
}

.MyServices-container .card-sized-box::-webkit-scrollbar {
    -ms-overflow-style: none; 
}