.HeadingListContent-container
{
    background-color: #040404;
}


.HeadingListContent-container .list-bullet
{
    margin: 0;
    margin-right: 5px;
}
.HeadingListContent-container .list-para
{
   color:#EAEBEB;
}


@media(min-width:1400px)
{
    .HeadingListContent-container .body-paragraph
    {
        line-height: 2;
        width: 70%;
    }

}