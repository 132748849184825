.CaseStudyHeroBanner-container
{
    background-image: url("../../../public/images/creatives/case-study-hero-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    min-height: 650px;
    overflow-y: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}
.CaseStudyHeroBanner-container::-webkit-scrollbar {
  display: none; 
}

.CaseStudyHeroBanner-container .main-heading,
.CaseStudyHeroBanner-container .main-heading span
{
    letter-spacing: 14px;
    font-size: 130px;
}

.CaseStudyHeroBanner-container .overlay
{
    background-color: #f0f2f5be;
}

.CaseStudyHeroBanner-container .about-hero-img
{
    height: 15rem;
}

.CaseStudyHeroBanner-container .main-heading
{
    position: relative;
}

.CaseStudyHeroBanner-container .main-heading .txt-1{
    position: relative;
    /* top: -80px; */
    left: 12%;
    z-index: 1;
    animation-name: CaseStudyHeroBanner-container-txt1;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

}
.CaseStudyHeroBanner-container .main-heading .about-hero-img{
  position: relative;
    z-index: 2;
    animation-name: CaseStudyHeroBanner-container-fade;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.CaseStudyHeroBanner-container .main-heading .txt-2{
    position: relative;
    left: -5%;
    z-index: 3;
    animation-name: CaseStudyHeroBanner-container-txt2;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@media(max-width:1340px)
{
    .CaseStudyHeroBanner-container .main-heading,
    .CaseStudyHeroBanner-container .main-heading span
    {
        letter-spacing: 10px;
        font-size: 100px;
    }
}

@media(max-width:1000px)
{
    .CaseStudyHeroBanner-container .main-heading,
    .CaseStudyHeroBanner-container .main-heading span
    {
        font-size: 90px;
    }
    .CaseStudyHeroBanner-container .main-heading .txt-1{
        top: 0;
        left: 0;
        animation: none;
    
    }
    
    .CaseStudyHeroBanner-container .main-heading .txt-2{
        left: 0;
        animation: none;
    }
    .CaseStudyHeroBanner-container
    {
        min-height: 100vh;
    }
    .CaseStudyHeroBanner-container .about-hero-img
    {
        height: 20rem;
    }
}

@media(min-width:1774px)
{
    .CaseStudyHeroBanner-container .main-heading,
    .CaseStudyHeroBanner-container .main-heading span
    {
        letter-spacing: 20px;
        font-size: 150px;

    }
    .CaseStudyHeroBanner-container .main-heading .txt-1{
        /* top: -80px; */
        left: 8%;
        z-index: 1;
        animation-name: CaseStudyHeroBanner-container-txt1-lg;
    
    }
    .CaseStudyHeroBanner-container .main-heading .about-hero-img{
      position: relative;
        z-index: 2;
        height: 20rem;
        left: -6%;
    }
    
    .CaseStudyHeroBanner-container .main-heading .txt-2{
        position: relative;
        left: -14%;
        z-index: 3;
        animation-name: CaseStudyHeroBanner-container-txt2-lg;
    }
}

@keyframes CaseStudyHeroBanner-container-txt1 {
    from {
      left: -100vw;
    }
    to {
      left: 12%;
    }
  }
  
  @keyframes CaseStudyHeroBanner-container-txt1-lg {
    from {
      left: -100vw;
    }
    to {
      left: 8%;
    }
  }
  @keyframes CaseStudyHeroBanner-container-txt2 {
    from {
      left: 100vw;
    }
    to {
      left: -5%;
    }
  }
  
  @keyframes CaseStudyHeroBanner-container-txt2-lg {
    from {
      left: 100vw;
    }
    to {
      left: -14%;
    }
  }
  @keyframes CaseStudyHeroBanner-container-fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }