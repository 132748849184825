.Header-container
{
    position: relative;
    z-index: 100;
}

.Header-container .nav-logo
{
    width: 250px;
    cursor: pointer;
}