.AboutText-container
{
    background-color: #040404;
}

.AboutText-container .heading-large
{

}

.AboutText-container .text-content .heading
{
    padding-right: 7rem;
}

@media(min-width:1400px)
{
    .AboutText-container .heading-large
    {
        font-size: 120px;
    }
    .AboutText-container .text-content .heading
    {
        padding-right: 12rem;
    }

}