.SubscribeNewsletter-container
{
    
    background-color: #040404;

}

.SubscribeNewsletter-container .title
{
    font-size: 30px;
}

.SubscribeNewsletter-container .exp-card-container p
{
    color:#eaebeb!important;
    font-size: 15px;
}

.SubscribeNewsletter-container .follow-container
{
    background-image: url("../../../public/images/creatives/newsletter-container-bg.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size:90% 80% ;
    position: relative;
    padding: 100px 1px;
}

.SubscribeNewsletter-container .follow-container .medal-icon
{
    height: 480px;
    position: absolute;
    top: -20px;
    right: 15%;
}

.SubscribeNewsletter-container .follow-container .social-icons
{
    width: 50px;
}

.SubscribeNewsletter-container .none-button
{
    border: none;
    background: transparent;
    font-size: 20px;
    font-weight: bold;
}
.SubscribeNewsletter-container .none-input
{
    border: none;
    background: transparent;
    color: white;
    
}

.SubscribeNewsletter-container .inp-container
{
    padding-bottom: 15px;
    border-bottom: 1px solid white;
}

.SubscribeNewsletter-container .none-input::placeholder
{
    color: white;
    
}
.SubscribeNewsletter-container .none-input:focus
{
    outline: none;

}

@media(max-width:1000px)
{
    .SubscribeNewsletter-container .follow-container
    {

        background-position: center;
       
        background-size:cover;
    }
    .SubscribeNewsletter-container .follow-container .medal-icon
    {
        position: relative;
        top: 0pc;
        right: 0;
        height: 380px;
    }
    .SubscribeNewsletter-container .follow-container .social-icons
    {
        width: 2.1em;
    }
}

@media(min-width:1400px)
{
    .SubscribeNewsletter-container .follow-container
    {
        background-image: url("../../../public/images/creatives/newsletter-container-bg.png");
        background-position: left;
        background-repeat: no-repeat;
        background-size:90% 80% ;
        position: relative;
        padding: 220px 1px;
    }
    .SubscribeNewsletter-container .follow-container .medal-icon
    {
        height: 800px;
        position: absolute;
        top: -10px;
        right: 12%;
    }

}