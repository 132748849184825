.CaseStudiesList-container
{
    background-color: #040404;
}

.CaseStudiesList-container .case-st-card
{
    margin-bottom: 40px;
    padding-bottom: 50px;
    border-bottom: 1px solid rgb(200, 200, 200);
}

.CaseStudiesList-container .case-st-card-anchor
{
    color: white;
    font-size: 16px!important;
    transition: .3s;
}
.CaseStudiesList-container .case-st-card-anchor:hover
{
    cursor: pointer;
    color: #F58E09;
}

.CaseStudiesList-container .case-st-card-icon
{
    width: 22px;
    height: 22px;
}

/* .CaseStudiesList-container .second .case-st-card
{
    transform: translateY(80px);
} */

.CaseStudiesList-container .card-img
{
    width: 100%;
}

@media(min-width:1700px)
{
    .CaseStudiesList-container .case-card-content
    {
        width: 80%;
        line-height: 1.9;
    }
}

@media(max-width:992px)
{
    .CaseStudiesList-container .case-st-card
    {
        margin-bottom: 60px;
    }
    .CaseStudiesList-container .second .case-st-card
    {
        transform: translateY(0px);
    }
}