.CaseStudyInnerHeroBanner-container
{
    background-color: #040404;
    height: auto;
    
    min-height: 100vh;
    overflow-y: hidden;
}

.CaseStudyInnerHeroBanner-container .main-heading,
.CaseStudyInnerHeroBanner-container .main-heading span
{
    letter-spacing: 14px;
    /* font-size: 20px; */
}

.CaseStudyInnerHeroBanner-container .hero-content
{
    width: 80%;
}

.CaseStudyInnerHeroBanner-container .border-right
{
    border-right: 1px solid white;
    height: 100%;
    width: 0;
    
}





@media(max-width:1340px)
{
    .CaseStudyInnerHeroBanner-container .main-heading,
    .CaseStudyInnerHeroBanner-container .main-heading span
    {
        letter-spacing: 10px;
        font-size: 80px;
    }


}

@media(max-width:1000px)
{
    .CaseStudyInnerHeroBanner-container
    {
        height: auto;
    }

    .CaseStudyInnerHeroBanner-container .main-heading,
    .CaseStudyInnerHeroBanner-container .main-heading span
    {
        font-size: 40px;
    }

    .CaseStudyInnerHeroBanner-container .hero-content
    {
        width: 100%;
    }
    
}

@media(min-width:1774px)
{
    .CaseStudyInnerHeroBanner-container .main-heading,
    .CaseStudyInnerHeroBanner-container .main-heading span
    {
        text-align: start;
        letter-spacing: 20px;
        font-size: 130px;

    }

    .CaseStudyInnerHeroBanner-container .hero-content
    {
        line-height: 2;
    }

    .CaseStudyInnerHeroBanner-container .hero-content
    {
        width: 80%;
    }
}