.ContactFormSection-container
{
    background-color: #040404;
}

.ContactFormSection-container .none-button
{
    border: none;
    background: transparent;
    font-size: 20px;
    font-weight: bold;
}
.ContactFormSection-container .none-input
{
    border: none;
    background: transparent;
    color: white;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 20px 15px;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.635);
    width: 90%;
    
}

.ContactFormSection-container .text-none
{
    width: 95%;
    resize: none;
}
.ContactFormSection-container .none-input::placeholder
{
    color: #EAEBEB;
    
}
.ContactFormSection-container .none-input:focus
{
    outline: none;

}
.ContactFormSection-container .social-icons
{
 transition: .3s;
}
.ContactFormSection-container .social-icons:hover
{
    cursor: pointer;
    transform: translateY(-10px);
}