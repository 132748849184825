.TextListContent-container
{
    background-color: #040404;
}
.TextListContent-container .container
{
    border-radius: 32px;
    
    
    background: #0f0f0f
}

.TextListContent-container .list-bullet
{
    margin: 0;
    margin-right: 5px;
}
.TextListContent-container .list-para
{
    color: rgb(187, 188, 188);
}


@media(min-width:1400px)
{
    .TextListContent-container .body-paragraph
    {
        line-height: 2;
        width: 70%;
    }

}