.BlogCard-container
{

}

.BlogCard-container .anchor
{
    text-decoration: underline;
    cursor: pointer;
    transition: .3s;
}
.BlogCard-container .anchor:hover
{
    color: #023c58;

}

.BlogCard-container .title
{
    font-size: 20px;
    font-weight: 700;
    color:#313131;
}
.BlogCard-container .side-card .img-container
{
    height: 130px;
}

.BlogCard-container .date
{
    font-size: 16px;
    color: #313131!important;
    font-weight: 600;
}

@media(min-width:1400px) {
    .BlogCard-container .side-card .img-container
    {
        height: 180px;
    }
    .BlogCard-container .title
    {
        font-size:30px;
        font-weight: 800;
        color: #313131;
    }
}