.ContactHeroBanner-container
{
   background-color: #040404;
    height: 100vh;
    min-height: 650px;
    overflow-y: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}
.ContactHeroBanner-container::-webkit-scrollbar {
  display: none; 
}

.ContactHeroBanner-container .main-heading,
.ContactHeroBanner-container .main-heading span
{
    letter-spacing: 14px;
    font-size: 130px;
}

.ContactHeroBanner-container .overlay
{
    background-color: #f0f2f5be;
}

.ContactHeroBanner-container .about-hero-img
{
    height: 20rem;
}

.ContactHeroBanner-container .main-heading
{
    position: relative;
}

.ContactHeroBanner-container .main-heading .txt-1{
    position: relative;
    top: -60px;
    left: 19%;
    z-index: 1;
    animation-name: ContactHeroBanner-container-txt1;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

}
.ContactHeroBanner-container .main-heading .about-hero-img{
  position: relative;
    z-index: 2;
    animation-name: ContactHeroBanner-container-fade;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

}

.ContactHeroBanner-container .main-heading .txt-2{
    position: relative;
    left: -10%;
    top: 50px;
    z-index: 3;
    animation-name: ContactHeroBanner-container-txt2;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

}

@media(max-width:1440px)
{
    .ContactHeroBanner-container .main-heading,
    .ContactHeroBanner-container .main-heading span
    {
        letter-spacing: 10px;
        font-size: 90px;
    }
}
@media(max-width:1200px)
{
    .ContactHeroBanner-container .main-heading,
    .ContactHeroBanner-container .main-heading span
    {
        letter-spacing: 10px;
        font-size: 80px;
    }

    .ContactHeroBanner-container .about-hero-img
    {
        height: 15rem;
    }
}

@media(max-width:992px)
{
    .ContactHeroBanner-container .main-heading,
    .ContactHeroBanner-container .main-heading span
    {
        font-size: 90px;
    }
    .ContactHeroBanner-container .main-heading .txt-1{
        top: 0;
        left: 0;
        animation: none;
    }
    
    .ContactHeroBanner-container .main-heading .txt-2{
        top: 0;
        left: 0;
        animation: none;
    }
    .ContactHeroBanner-container
    {
        min-height: 100vh;
    }
    .ContactHeroBanner-container .about-hero-img
    {
        height: 15rem;
        animation: none;
    }
}

@media(min-width:1774px)
{
    .ContactHeroBanner-container .main-heading,
    .ContactHeroBanner-container .main-heading span
    {
        letter-spacing: 20px;
        font-size: 150px;

    }
    .ContactHeroBanner-container .main-heading .txt-1{
        /* top: -80px; */
        left: 18%;
        top: -80px;
        z-index: 1;
        animation-name: ContactHeroBanner-container-txt1-lg;
    }
    .ContactHeroBanner-container .main-heading .about-hero-img{
      position: relative;
        z-index: 2;
        height:27rem;
        left: -6%;
    }
    
    .ContactHeroBanner-container .main-heading .txt-2{
        position: relative;
        left: -17%;
        top: 80px;
        z-index: 3;
        animation-name: ContactHeroBanner-container-txt2-lg;
    }
}

@keyframes ContactHeroBanner-container-txt1 {
    from {
      top: -100vh;
    }
    to {
      top: -60px;
    }
  }
  
  @keyframes ContactHeroBanner-container-txt1-lg {
    from {
      top: -100vh;
    }
    to {
      top: -80px;
    }
  }
  @keyframes ContactHeroBanner-container-txt2 {
    from {
      top: 100vh;
    }
    to {
      top: 50px;
    }
  }
  
  @keyframes ContactHeroBanner-container-txt2-lg {
    from {
      top: 100vh;
    }
    to {
      top: 80px;
    }
  }
  @keyframes ContactHeroBanner-container-fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }