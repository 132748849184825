.PartnerCompanies-container
{
    background-image: url("../../../public/images/creatives/home-hero-bg.png");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
}
.PartnerCompanies-container .swiper-slide
{
    text-align: center;
}

@media(max-width:992px)
{
    .PartnerCompanies-container
    {
       
        background-position: bottom;
    }
}