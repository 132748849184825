.CaseStudyCard-container
{
    background-color: #040404;
}

.CaseStudyCard-container .line-seprater
{
    border: 1px solid grey;
    width: 100%;
    height: 0;
    margin: auto;
}

.CaseStudyCard-container .CardBox-container
{
    margin: 20px 1px;
    background-color: #0f0f0f;
    border-radius: 10px;
}

.CaseStudyCard-container .case-card-arrow
{
    cursor: pointer;
}


.CardBox-container .card-thumb
{
    border-radius: 15px;
    width: 100%;
    min-height: 200px;
}

.CardBox-container .card-title
{
    font-size: 70px;
}

@media(max-width:993px)
{
    .CardBox-container .card-title
    {
        font-size: 40px;
    }
}
@media(max-width:768px)
{
    .CardBox-container .card-title
    {
        font-size: 32px;
    }
}