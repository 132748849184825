.PastExperience-container
{
    
    background-color: #040404;

}

.PastExperience-container .title
{
    font-size: 30px;
}

.PastExperience-container .exp-card-container 
{
    border-bottom: 1px solid white;
}
.PastExperience-container .exp-card-container p
{
    color:#eaebeb!important;
    font-size: 15px;
}

.PastExperience-container .follow-container
{
    background-image: url("../../../public/images/creatives/follow-container-bg.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size:90% 80% ;
    position: relative;
    padding: 100px 1px;
}

.PastExperience-container .follow-container .medal-icon
{
    height: 480px;
    position: absolute;
    top: -20px;
    right: 15%;
}

.PastExperience-container .follow-container .social-icons
{
    width: 50px;
    transition: .3s
}
.PastExperience-container .follow-container .social-icons:hover
{
    cursor: pointer;
    transform: translateY(-10px);
}

@media(min-width : 1400px)
{
    .PastExperience-container .follow-container
    {
    
        padding: 220px 1px;
    }

    .PastExperience-container .follow-container .medal-icon
    {
        height: 830px;
        position: absolute;
        top: -20px;
        right: 15%;
    }
}

@media(max-width:1000px)
{
    .PastExperience-container .follow-container
    {

        background-position: center;
       
        background-size:cover;
    }
    .PastExperience-container .follow-container .medal-icon
    {
        position: relative;
        top: 0pc;
        right: 0;
    }
    .PastExperience-container .follow-container .social-icons
    {
        width: 2.1em;
    }
}