.MyAcievment-container
{
    background-image: url("../../../public/images/creatives/MyAcievment-bg.jpg");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
}

.MyAcievment-container .title
{
    color: #313131;
    font-size: 25px;
    
}
.MyAcievment-container .large-num-text
{
    font-size: 110px;
    letter-spacing: 5px;
    font-weight: bold;
}

.MyAcievment-container .text-content .content
{
    width: 80%;
}


@media(max-width:1000px)
{
    .MyAcievment-container .large-num-text
    {
        font-size: 50px;
        letter-spacing: 1px;

    }
    .MyAcievment-container .text-content .content
    {
        width: 100%;
    }
}