.HeaderBanner-container
{
    background-color: white;
    min-height: max-content;
    position: absolute;
    z-index: 1000;
}

.HeaderBanner-container .left-pane
{
    background-color: #F0F2F5;
}

.HeaderBanner-container .right-pane
{
    background-color: black;
}

.HeaderBanner-container .text-content .body-paragraph
{
    font-size: 20px;
}
.HeaderBanner-container .text-content .anchor
{
    font-size: 25px;
    font-weight: bold;
    text-decoration: none;
}
.HeaderBanner-container .text-content .mail-anchor
{
    text-decoration: none;
}



.disp-show
{
    transition: .5s;
    opacity: 1;
    visibility: visible;
}

.disp-none
{
    opacity: 0;
    transition: .5s;
    visibility: hidden;
}

.HeaderBanner-container .social-icons
{
    width: 30px;
    height: 30px;
}


@media(min-width:1400px)
{
    .HeaderBanner-container
    {
        background-color: white;
        min-height: max-content;
        position: absolute;
        z-index: 1000;
        height: 100vh;
    }

    .HeaderBanner-container .nav-main
    {
        justify-content: center;
    }
    .HeaderBanner-container .social-icons
    {
        width: 50px;
        height: 50px;
    }
    .HeaderBanner-container .text-content .anchor
    {
        font-size: 35px;
        font-weight: bold;
        text-decoration: none;
        padding-bottom: 15px;
    }

}