.ContactLocation-container
{
    background-color: #040404;
}

.ContactLocation-container .location-container
{
    padding-left: 40px;
}


@media(min-width:1400px)
{
    .ContactLocation-container .body-paragraph
    {
        line-height: 2;
        width: 70%;
    }
    .ContactLocation-container .title
        {
            font-size: 108px!important;
        }
}

@media(max-width:1000px)
{
    .ContactLocation-container .location-container
    {
        padding-left: 0px;
    }
}