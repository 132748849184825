.BlogsListing-container
{
    background-color: #040404;
}

.BlogsListing-container .latest-blog-container
{
    
border-radius: 16px;
background-color: #0f0f0f;
}

.BlogsListing-container .blog-card-anchor
{
    color: white;
    transition: .3s;
}
.BlogsListing-container .blog-card-anchor:hover
{
    cursor: pointer;
    color: #F58E09;
}

.BlogsListing-container .catagory-container
{
    cursor: pointer;
    padding:10px 22px;       
    border-radius: 26px;
    background-color: #0f0f0f;
}
.BlogsListing-container .catagory-container h4
{
        
    color: white;
}
.BlogsListing-container .active
{    
border-radius: 26px;
background-color: #eaebeb;
}
.BlogsListing-container .active h4
{    
color: black!important;
}

