.HeadingContent-container
{
    background-color: #EAEBEB;
}


@media(min-width:1400px)
{
    .HeadingContent-container .body-paragraph
    {
        line-height: 2;
        width: 70%;
    }

}